<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary">eWork</h2>
      </b-link>

      <!-- INSERT EMAIL -->
      <b-card class="mb-0 pb-3">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary">CONNEXION</h2>
        </b-link>

        <b-card-title class="mb-1">
          Bienvenue sur votre Espace Client
        </b-card-title>
        <b-card-text class="mb-2">
          Veuillez renseigner votre adresse email.
        </b-card-text>

        <!-- form -->
        <validation-observer ref="loginForm">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <!-- email -->
            <b-form-group label-for="email" label="Email">
              <validation-provider #default="{ errors }" name="Email" rules="required|email">
                <b-form-input id="email" v-model="data.email" name="login-email"
                  :state="errors.length > 0 ? false : null" placeholder="john@example.com" autofocus />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group v-if="is_password == 2">
              <div class="d-flex justify-content-between">
                <label for="password">Mot de passe</label>
                <b-link :to="{ name: 'customer-forgot-password' }">
                  <small>Mot de passe oublié ?</small>
                </b-link>
              </div>
              <validation-provider #default="{ errors }" name="Password" rules="required">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="password" v-model="data.password" :type="passwordFieldType"
                    class="form-control-merge" :state="errors.length > 0 ? false : null" name="login-password"
                    placeholder="Password" />

                  <b-input-group-append is-text>
                    <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button variant="primary" type="submit" block :disabled="loading" @click="logCustomer">
              <b-spinner small v-show="loading"></b-spinner> Se connecter
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      data: {
        email: "",
        password: "",
      },
      confirm_password: "",
      is_password: 0,
      status: "",
      // validation rules
      required,
      email,
    };
  },
  computed: {
    ...mapState('customer', ['customerInfo', 'enterpriseInfo']),



    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },

  mounted() {

  },



  methods: {
    ...mapActions('customer', ['login', 'getCustomerByEmail', 'createCustomerPassword']),

    logCustomer: async function () {
      this.loading = true;
      const isValid = await this.$refs.loginForm.validate();
      if (!isValid) return;
      if (this.data.password != "") {
        this.is_password = 2;
        this.login(this.$formData(this.data)).then((res) => {
          this.$router.replace({ name: 'customer-space' }).then(() => {
            window.location.reload();
          })
        }).catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Données de connexion invalides',
              icon: "TrashIcon",
              variant: "danger",
            },
          });
        });;
      } else {
        this.getCustomerByEmail(this.data.email).then((res) => {
          this.loading = false;
          if (this.customerInfo.password == null) {
            this.login(this.$formData(this.data))
            this.$router.replace({ name: 'customer-validate' });
          } else {
            this.is_password = 2;
          }
        }).catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Données de connexion invalides',
              icon: "TrashIcon",
              variant: "danger",
            },
          });
        });
      }
    },


  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
